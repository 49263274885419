* {
    margin: 0;
    padding: 0;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1200px) {
    video {
        display: none;
    }
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Lato'), url(./fonts/Ubuntu-Medium.ttf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: local('Lato'), url(./fonts/GreycliffCF-Bold.ttf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: local('Lato'), url(./fonts/Lora-SemiBold.ttf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Savoye LET';
    src: local('Lato'), url(./fonts/Savoye-LET-Plain1.0.woff) format('opentype');
    font-display: swap;
}
