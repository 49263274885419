html{
  scroll-behavior: smooth;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 110vh;
}

#block-hamburger{
    width: 40px;
    height: 50px;
    border: none;
    background-color: transparent;
    font-size: 40px;
    color: white;
    text-align: end;
    position: absolute;
    top: 50px;
    right: 40px;
    display: none;
}

#navbar{
    position: absolute;
    z-index: 1030;
    background-color: transparent;
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#block-links-web{
    flex-grow: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#block-links-mobile{
    opacity: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: #7e4fb4;
    position: absolute;
    top: 0;
}

.link{
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    color: white;
    font-size: larger;
    font-family: 'Nunito Sans';
}

#block-contact{
    flex-grow: 1;
    text-align: center;
}

#button-contact{
    border: 2px solid white;
    background: #11ffee00;
    color: white;
    height: 50px;
    width: 150px;
    border-radius: 10px;
    font-family: 'Nunito Sans';
    font-size: 1.2em;
    font-size: 20;
    position: relative;
}

#button-contact-phone{
  border: 2px solid white;
  background: #11ffee00;
  color: white;
  height: 50px;
  width: 150px;
  border-radius: 10px;
  font-family: 'Nunito Sans';
  font-size: 1.2em;
  font-size: 20;
  position: relative;
}

#block-logo{
    position: initial;
    z-index: 2;
    flex-grow: 0.3;
    text-align: center;
}

@media screen and (min-width:1201px){
    #block-links-mobile{
        display: none!important;
    }
}

@media screen and (max-width: 1200px){

    #block-links-web{
        display: none;
    }
    #block-hamburger{
        display: block;
        position: absolute;
    }
    #navbar{
        flex-direction: column;
        justify-content: center;
        z-index: 0;
        height: 100%;
    }
    .link{
        font-size: 26px;
        margin-bottom: 40px;
    }

    #block-contact{
        flex-grow: 1;
        font-size: 15px; 
    }

    #block-logo{
        flex-grow: 0;
    }

    #link-logo{
        position: absolute;
        left: 40px;
        top: 50px;
    }
    
}



menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    /* border: 3px solid #fff; */
  }
  .menu-btn__burger {
    width: 50px;
    height: 6px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 6px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-16px);
  }
  .menu-btn__burger::after {
    transform: translateY(16px);
  }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
  


  /* ----------------------------------------------
 * Generated by Animista on 2022-8-2 20:56:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-top {
	-webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-2 20:59:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
  }
  