.img-wrapper{
    overflow:hidden;
}

img.hover-zoom{
    transition: all 0.3s ease 0s;
}

.imgphone-slider  {
    height: 172px;
    width: 172px;
}

img.hover-zoom:hover {
    transform: scale(1.25);
}

.react-slideshow-container .nav:first-of-type {
    left: 10%;
    position: absolute;
    margin-left: 10%;
}

.react-slideshow-container .nav {
    z-index: 10;
    position: absolute;
    left: 90%;
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    .react-slideshow-container .nav {
        left: 95%;
    }
    .react-slideshow-container .nav:first-of-type {
        margin-left: 5%;
    }
}

@media screen and (max-width: 1400px) {
    .react-slideshow-container .nav {
        left: 100%;
    }
    .react-slideshow-container .nav:first-of-type {
        margin-left: 0;
    }
}
